<!-- Services -->
<section id="leistungen" class="home-section bg-white">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section-heading">
          <h2>Leistungen</h2>
          <p>Für welche Bereiche interessieren Sie sich?</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
        <div class="service-box wow bounceInDown" data-wow-delay="0.1s">
          <i class="fa fa-code fa-4x"></i>
          <h4>Programmierung</h4>
          <p>
            Das Projekt wird je nach Bedarf mit unterschiedlichen Technologien (objektorientiert) umgesetzt.
            Das Frontend wird mit Angular CLI umgesetzt und für die Stile und Effekte wird Bootstrap, jQuery, Javascript und CSS standardmäßig verwendet.
            Für die Entwicklungsumgebung kommt Docker und IntelliJ oder Atom.io unter Windows 10 zum Einsatz.
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" data-wow-delay="0.7s">
        <div class="service-box wow bounceInDown" data-wow-delay="0.1s">
          <i class="fa fa-database fa-4x"></i>
          <h4>Datenbanken</h4>
          <p>
            Eine Datenbank ermöglicht die dauerhafte, zentrale Speicherung von Daten und kann diese eigenständig verwalten.
            Das Speichern und Abfragen erfolgt mit einer speziellen Skriptsprache. Für diese Zwecke wird hierfür MySQL als Datenbank verwendet.
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" data-wow-delay="0.7s">
        <div class="service-box wow bounceInDown" data-wow-delay="0.1s">
          <i class="fa fa-server fa-4x"></i>
          <h4>Softwareentwicklung</h4>
          <p>
            Eine Software ermöglicht den manuellen Aufwand zu minimieren bis hin komplett abzulösen. Die Aufwände können
            durch einen Automatismus ersetzt werden, sodass Sie sich auf das wesentliche konzentrieren können. Eine Individual-Software,
            als eine Software as Service (SaaS) lässt sich daher ideal bieten.
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" data-wow-delay="0.7s">
        <div class="service-box wow bounceInDown" data-wow-delay="0.1s">
          <i class="fa fa-desktop fa-4x"></i>
          <h4>Webentwicklung</h4>
          <p>
            Eine Online Präsenz ist im heutigen Zeitalter schon mittlerweile ein Muss!
            Eine Homepage ist online weltweit erreichbar - über PC, Laptop, Tablet oder Smartphone gleichermaßen -
            und das von allen Benutzern, Partnern und potenziellen Kunden gleichzeitig.
            Eine Webanwendung muss nicht auf dem Endgerät installiert werden und ist somit plattformunabhängig.
            Sie können die Software mit dem Mac genauso nutzen wie mit Ihrem Windows-PC oder dem Smartphone.
            Hierzu sind auch keine lästige Updates notwendig.
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" data-wow-delay="0.7s">
        <div class="service-box wow bounceInDown" data-wow-delay="0.1s">
          <i class="fa fa-cogs fa-4x"></i>
          <h4>Wartung</h4>
          <p>
            Die Zeiten von "Entwickelt und vergessen" sind schon lange vorbei. Im Internet bedeutet es heutzutage auf dem neuesten
            Stand zu bleiben. Eine Website oder eine Software zu haben bedeutet auch Wartung. Es muss sichergestellt werden,
            dass Webseiten oder Software sicher und auf dem neusten Stand ist und auch bleibt.
            Lassen Sie uns das Geschäft mit der Pflege und Wartung Ihrer Website oder Software aus Ihren Händen nehmen, während Sie Ihr Geschäft führen!
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" data-wow-delay="0.3s">
        <div class="service-box wow bounceInDown" data-wow-delay="0.1s">
          <i class="fa fa-eye fa-4x"></i>
          <h4>SEO / SEM</h4>
          <p>
            Wollen Sie besser in den Suchmaschinen gefunden werden? Dann sind wir der richtige Ansprechspartner für Sie!
            Durch gezielte Maßnahmen in SEO (Suchmaschinenoptimierung) und SEM (Suchmaschinenmarketing) gelangen Sie bessere
            Ranking in den Suchergebnissen von Google, Bing usw.
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" data-wow-delay="0.5s">
        <div class="service-box wow bounceInDown" data-wow-delay="0.1s">
          <i class="fa fa-tablet fa-4x"></i>
          <h4>Responsive Layout</h4>
          <p>
            Mit responsive optimierten Webseiten ist es längst nicht mehr nötig speziell für bestimmte Ausgabegeräte
            jeweils individuell erstellte Applikationen zu erstellen sondern es erfolgt eine Optimierung für alle Endgeräte.
            Testen Sie doch einfach diese Seite auf verschiedenen Endgeräten.
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3" data-wow-delay="0.7s">
        <div class="service-box wow bounceInDown" data-wow-delay="0.1s">
          <i class="fa fa-support fa-4x"></i>
          <h4>Support & Beratung</h4>
          <p>
            Kompetente und umfangreiche Beratung zu sämtlichen Aspekten im Bereich Web- und Softwareentwicklung (Tech Stack).
            Konzeption, Planung, Abstimmung, Qualitätssicherung als auch Dokumentationen gehören selbstverständlich zum Projekt.
            In Sachen Support sind wir ihr erster Ansprechspartner.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
