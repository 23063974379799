<!-- Tech Stack -->
<section id="works" class="home-section bg-gray">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section-heading">
          <h2>Tech Stack</h2>
          <p>Für die perfekte Pflege & Umsetzung Ihrer Website, Software oder Produkte.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col techstackMargin">

        <ul class="lb-album">

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/html5.png" alt="HTML5" class="image">
              <div class="middle">
                <div class="text">HTML5</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/css.png" alt="CSS/3" class="image">
              <div class="middle">
                <div class="text">CSS/3</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/javascript.png" alt="Javascript" class="image">
              <div class="middle">
                <div class="text">Javascript</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/jquery.png" alt="jQuery" class="image">
              <div class="middle">
                <div class="text">jQuery</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/bootstrap.png" alt="Bootstrap" class="image">
              <div class="middle">
                <div class="text">Bootstrap</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/angular.png" alt="Angular" class="image">
              <div class="middle">
                <div class="text">Angular</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/git.png" alt="Git" class="image">
              <div class="middle">
                <div class="text">Git</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/mysql.png" alt="MySQL" class="image">
              <div class="middle">
                <div class="text">MySQL</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/php.png" alt="PHP" class="image">
              <div class="middle">
                <div class="text">PHP</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/java.png" alt="Java" class="image">
              <div class="middle">
                <div class="text">Java</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/spring.png" alt="Spring Boot" class="image">
              <div class="middle">
                <div class="text">Spring Boot</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/gradle.png" alt="Gradle" class="image">
              <div class="middle">
                <div class="text">Gradle</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/python.png" alt="Python" class="image">
              <div class="middle">
                <div class="text">Python</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/filemaker.png" alt="Filemaker" class="image">
              <div class="middle">
                <div class="text">Filemaker</div>
              </div>
            </div>
          </li>

          <li>
            <div class="techstack">
              <img src="assets/img/works/thumbs/docker.png" alt="Docker" class="image">
              <div class="middle">
                <div class="text">Docker</div>
              </div>
            </div>
          </li>

        </ul>

      </div>
    </div>
  </div>
</section>
