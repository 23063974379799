<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" routerLink="#"><img id="logo" alt="Webentwickler, Softwareentwickler" src="assets/img/websoftops_logo.jpg"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <p class="animate_fade_in" style="opacity: 1; right: 0px;">
    <a [href]="emailString" target="_top"><i class="fa fa-envelope rounded-icon"></i> </a>
    <a [href]="whatsappString" target="_top"><i class="fa fa-whatsapp rounded-icon"></i></a>
    <a [href]="phoneString" target="_top"><i class="fa fa-phone rounded-icon"></i></a>
  </p>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item active">
        <a class="nav-link" routerLink="/home">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/home#about">Über uns <span class="sr-only"></span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/kompetenzen">Kompetenzen <span class="sr-only"></span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/home#leistungen">Leistungen <span class="sr-only"></span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/home#contact">Kontakt <span class="sr-only"></span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/impressum">Impressum <span class="sr-only"></span></a>
      </li>
    </ul>>
  </div>
</nav>


