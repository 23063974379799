import { Component } from '@angular/core';
import { SEOService } from './service/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [SEOService]
})

export class AppComponent {

  constructor(private seoService: SEOService) {
    this.addMetaTags();
  }

  addMetaTags() {
     this.seoService.addMetaTags();
  }
  updateMetaTags() {
     this.seoService.updateMetaTags();
  }
  removeMetaTags() {
     this.seoService.removeMetaTags();
  }
  getMetaTags() {
     this.seoService.getMetaTags();
  }
}
