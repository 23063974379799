import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  providers: [{
        provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
    }]
})
export class ContactComponent implements OnInit {
   isLinear = true;
   firstFormGroup: FormGroup;
   secondFormGroup: FormGroup;
   finalFormGroup: FormGroup;
   SERVER_URL = "https://www.websoftops.de/request.php";
   public data;
   emailString = "mailto:tahirmzafar@websoftops.de";
   phoneString = "tel:+49 69 257 107 59";
   whatsappString = "tel:+49 176 307 134 42";

  constructor( private _formBuilder: FormBuilder, private httpClient: HttpClient ) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
       name: ['', [Validators.required, Validators.minLength(4)]],
       unternehmen: ['', [Validators.required, Validators.minLength(4)]],
       email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
       betreff: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
       projekt: ['', [Validators.required, Validators.minLength(10)]]
    });

  }

  get name() { return this.firstFormGroup.get('name'); }
  get unternehmen() { return this.firstFormGroup.get('unternehmen'); }
  get email() { return this.firstFormGroup.get('email'); }
  get projekt() { return this.secondFormGroup.get('projekt'); }

  onSubmit() {
    this.finalFormGroup = new FormGroup({userInfo:this.firstFormGroup, projectInfo:this.secondFormGroup})
    this.httpClient.post<any>(this.SERVER_URL, this.finalFormGroup.value)
    .subscribe(
      (res) => {  this.data = res;
                  console.log(this.data);
                },
      (err) => { this.data = err;
                 console.log(this.data);
                }
    );
  }

}
