<div class="container">
  <div class="row">
    <div class="col">
      <div class="section-heading">
        <h2>Über uns</h2>
        <p>Fachliche Kompetenz im Bereich Web- und Softwareentwicklung</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="col wow bounceInDown ptext">
        <p>
          Wir setzen den Fokus des Unternehmens auf Entwicklung
          von Web- und Softwarelösungen. Dieser ist sowohl für kleine bis mittelständische Unternehmen, als auch für Privatpersonen optimal geeignet.
          Seit über mehreren Jahren sammeln wir Erfahrungen, mit denen wir den besten Weg finden, um alle Kundenbedürfnisse zu erfüllen.
          Wir gehen sehr gerne auf Ihre individuellen Wünsche und firmenspezifische Ansprüche ein und sind in der Lage schnell und effizient
          zu reagieren. Unsere Servicemitarbeiter, Web- und Softwareentwickler liefern eine effiziente Betreuung und Unterstützung.
          Auf Ihrem Wege in eine erfolgreiche Zukunft möchten wir Ihr Begleiter sein!
        </p>
        <p>
          Aktuell beschäftigen uns u.a. die Themen DevOps, PHP Entwicklung, Spring Boot, REST APIs, Software Architekturen,
          Angular Entwicklung, Docker Containerisierung, Content Management Systeme, MySQL uvm.
          Basierend auf diesem breiten Wissen und mit Blick auf die neuesten technologischen Standards und Werkzeuge programmieren wir hochwertige Lösungen -
          in Zukunft gerne auch für Sie.
        </p>
        <p>
          Wir glauben, dass IT-Lösungen nicht nur innovativ sein und perfekt zu den Geschäftsanforderungen passen sollten,
          sondern sich auch positiv auf die Umwelt auswirken sollten. Deshalb sind alle unsere Lösungen papierlos.
          Schauen Sie sich gerne unsere <a routerLink="/kompetenzen">Kompetenzen</a> an oder <a href="/home#contact">kontaktieren</a> Sie uns!
        </p>
      </div>
    </div>
  </div>


</div>
