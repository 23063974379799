import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})
export class CarouselComponent implements OnInit {

  //images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  images = ['optimization', 'development', 'analyzation', 'development2'].map((n) => `assets/img/${n}.jpg`);

  constructor(config: NgbCarouselConfig) {
      //
      config.interval = 2000;
      config.keyboard = true;
      config.pauseOnHover = true;
  }

  ngOnInit(): void {
  }

}
