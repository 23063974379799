import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  emailString = "mailto:tahirmzafar@websoftops.de";
  phoneString = "tel:+49 69 257 107 59";
  whatsappString = "tel:+49 176 307 134 42";

  constructor() { }

  ngOnInit(): void {
  }

}
