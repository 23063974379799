<div class="container">
  <div class="row">
    <div class="col">
      <div class="section-heading">
        <h2>Über uns</h2>
        <p>Fachliche Kompetenz im Bereich Web- und Softwareentwicklung</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
      <div class="box-team wow bounceInDown" data-wow-delay="0.1s">
        <img src="assets/img/tahir_zafar.png" alt="Freiberufler, Softwareentwickler, Webentwickler" class="img-responsive" />
        <h4>Tahir M. Zafar</h4>
        <p>Web- & Softwareentwickler</p>
      </div>
    </div>
    <div class="col wow bounceInDown ptext">
      <p>
        Mein Name ist Tahir Zafar (34) aus 63165 Mühlheim am Main. Ich arbeite im Bereich DevOps und als IT Freelancer
        im Bereich PHP Backend Entwicklung und mit Angular in der Frontend Entwicklung.<br><br>

        Nach anfänglichen Schritten in HTML, CSS und Javascript kam ich 2014 bis 2017, während meiner Ausbildung zum
        Fachinformatiker in der Fachrichtung Anwendungsentwicklung schwerpunktmäßig mit Filemaker in Berührung. Dies
        hatte mein Interesse geweckt mich leidenschaftlich in Sachen Programmierung fortzubilden und kam bislang
        mit mehreren Technologien in Berührung. Mein Aufgabengebiet umfasste vorallem die Konzeption, Planung und Umsetzung
        von diversen agilen Softwareprojekten. Dank diesen vielen umfangreichen und spannenden Projekten konnte ich ein enormes
        Wissen und eine Leidenschaft für Entwicklung aufbauen.<br><br>

        Aktuell beschäftigen mich u.a. die Themen DevOps, PHP Entwicklung, Spring Boot, REST APIs, Software Architekturen,
        Angular Entwicklung, Docker Containerisierung, Content Management Systeme, MySQL uvm.<br><br>

        Seit Ende 2019 habe ich meine Tätigkeit als Freelancer im Bereich Web- und Softwareentwicklung aufgenommen und
        arbeite für kleine bis mittelständische Unternehmen und Privatkunden. Wenn ich Ihr Interesse geweckt habe,
        schauen Sie sich gerne meine <a routerLink="/kompetenzen">Kompetenzen</a> an oder <a href="/home#contact">kontaktieren</a> Sie mich!
      </p>
    </div>
  </div>
</div>
