<!-- Tech Stack -->
<section id="works" class="home-section bg-gray">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section-heading">
          <h2>Erfahrungen - Skills</h2>
          <p>Diese Werte sind Selbsteinschätzungen und sind nur als grobe Richtlinie für zukünftige Arbeitgeber zu verstehen.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h4>Frameworks</h4>
        <div class="progressbar">
          <div class="skill" style="width: 90%">AngularJS<span class="percent">90%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 90%">Bootstrap<span class="percent">90%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 80%">JQuery, JQuery UI<span class="percent">80%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 40%">Node.js<span class="percent">40%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 60%">YAML<span class="percent">60%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 60%">Spring Boot<span class="percent">60%</span></div>
        </div>
      </div>
      <div class="col-md-6">
        <h4>Programmiersprachen</h4>
        <div class="progressbar">
          <div class="skill" style="width: 75%">PHP<span class="percent">75%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 70%">Java<span class="percent">70%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 90%">MySQL<span class="percent">90%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 90%">Javascript<span class="percent">90%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 60%">Typescript<span class="percent">70%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 90%">HTML, HTML5, XML, XHTML<span class="percent">90%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 60%">Python<span class="percent">60%</span></div>
        </div>
        <div class="progressbar">
          <div class="skill" style="width: 70%">Angular<span class="percent">70%</span></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="section-heading">
          <h4>Weiteres</h4>
          <div class="progressbar">
            <div class="skill" style="width: 90%">CSS<span class="percent">90%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 80%">Docker (Container)<span class="percent">80%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 90%">DevOps<span class="percent">90%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 90%">Software as a Service (SaaS)<span class="percent">90%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 90%">Datenbankentwicklung<span class="percent">90%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 85%">Frontend Entwicklung<span class="percent">85%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 90%">Projektmanagement<span class="percent">90%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 90%">Support<span class="percent">90%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 70%">Suchmaschinenoptimierung (SEO)<span class="percent">70%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 70%">Android Development<span class="percent">70%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 70%">JUnit/PHPUnit<span class="percent">70%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 60%">Wordpress<span class="percent">60%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 60%">RESTful Web Services<span class="percent">60%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 60%">Test Driven Development (TDD)<span class="percent">60%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 50%">Shell Scripting<span class="percent">50%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 70%">ETL (Extract, Transform, Load)<span class="percent">70%</span></div>
          </div>
          <div class="progressbar">
            <div class="skill" style="width: 100%">Warenwirtschaftssystem<span class="percent">100%</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h4>Arbeitserfahrung</h4>
        <p id="erfahrung">
          Bitte beachten Sie, dass die angegebenen Kentnisse möglicherweise nicht auf dem aktuellsten Stand sind.
          Schauen Sie sich auch in meinem <a href="https://www.xing.com/profile/Tahir_Zafar2/">Xing Profil</a> um auf
          den neusten Stand zu bleiben.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul class="list-unstyled">
          <li>2019 - heute: Freelancer - PHP/Angular Softwareentwickler</li>
          <li>2018 - heute: Angestellter Software Developer</li>
          <li>2017 - 2018: Angestellter Software Solutions Engineer</li>
          <li>2013 - 2017: Ausbildung zum Fachinformatiker Anwendungsentwicklung</li>
        </ul>
      </div>
    </div>
  </div>
</section>
