<div class="container-fluid">
  <ngb-carousel *ngIf="images">
    <ng-template ngbSlide>
      <div class="wrapper">
        <img [src]="images[0]" alt="Optimierung">
      </div>
      <div class="carousel-caption">
        <h3>Optimierung</h3>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="wrapper">
        <img [src]="images[1]" alt="Präzision">
      </div>
      <div class="carousel-caption">
        <h3>Präzision</h3>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="wrapper">
        <img [src]="images[2]" alt="Analysierung">
      </div>
      <div class="carousel-caption">
        <h3>Analysierung</h3>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="wrapper">
        <img [src]="images[3]" alt="Dokumentation">
      </div>
      <div class="carousel-caption">
        <h3>Dokumentation</h3>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
