<!-- Contact -->
<section id="contact" class="home-section bg-white">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="section-heading">
          <h2>Kontaktieren Sie uns</h2>
          <p>Bei Anfragen aus den Bereichen Webentwicklung, SEO-Optimierung oder Datenbanken kann oftmals mit schneller Verfügbarkeit weitergeholfen werden.</p>
          <p>Selbstverständlich sind auch Anfragen für umfangreiche Projekte stets willkommen.</p>
          <p>Geben Sie bitte noch an, auf welchem Wege wir Sie kontaktieren sollen. Wir werden uns dann innerhalb der nächsten 24 Stunden bei Ihnen melden.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-horizontal-stepper #stepper="matHorizontalStepper" [linear]="true">
          <mat-step [stepControl]="firstFormGroup" errorMessage="Bitte füllen Sie alle Felder aus!">
            <form [formGroup]="firstFormGroup" class="contactForm">
              <ng-template matStepLabel>Kontaktaufnahme</ng-template>
              <div class="row">
                <div class="col">
                  <mat-form-field class="form-group">
                    <input matInput type="text" name="name" class="form-control" placeholder="Ihr Name" formControlName="name" required/>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                      <div *ngIf="name.errors.required">
                        Name ist erforderlich.
                      </div>
                      <div *ngIf="name.errors.minlength">
                        Name muss mindestens 4 Buchstaben enthalten.
                      </div>
                    </div>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field class="form-group">
                    <input matInput type="text" name="unternehmen" class="form-control" placeholder="Unternehmen" formControlName="unternehmen" required/>
                    <div *ngIf="unternehmen.invalid && (unternehmen.dirty || unternehmen.touched)" class="alert alert-danger">
                      <div *ngIf="unternehmen.errors.required">
                        Unternehmen ist erforderlich.
                      </div>
                      <div *ngIf="unternehmen.errors.minlength">
                        Unternehmen muss mindestens 4 Buchstaben enthalten.
                      </div>
                    </div>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field class="form-group">
                    <input matInput type="text" name="email" class="form-control" placeholder="Email" formControlName="email" required/>
                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                      <div *ngIf="email.errors.required">
                        Email ist erforderlich.
                      </div>
                      <div *ngIf="email.errors.pattern">
                        Bitte geben Sie eine gültige Email Adresse ein.
                      </div>
                    </div>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col" id="radioButtons">
                  <p><b>Wofür interessieren Sie sich?</b></p>
                  <mat-radio-group aria-label="Select an option" formControlName="betreff" name="radioOptions" required>
                    <div class="radio">
                      <mat-radio-button value="Neue Website">Neue Website</mat-radio-button>
                    </div>
                    <div class="radio">
                      <mat-radio-button value="Relaunch Website">Relaunch Website</mat-radio-button>
                    </div>
                    <div class="radio">
                      <mat-radio-button value="Unterstützung Website">Unterstützung bei einer Website</mat-radio-button>
                    </div>
                    <div class="radio">
                      <mat-radio-button value="Neue Software">Neue Software</mat-radio-button>
                    </div>
                    <div class="radio">
                      <mat-radio-button value="Relaunch Software">Relaunch Software</mat-radio-button>
                    </div>
                    <div class="radio">
                      <mat-radio-button value="Unterstützung Software">Unterstützung bei einer Software</mat-radio-button>
                    </div>
                    <div class="radio">
                      <mat-radio-button value="Support/Beratung">Support / Beratung</mat-radio-button>
                    </div>
                    <div class="radio">
                      <mat-radio-button value="SEO/SEM">SEO / SEM</mat-radio-button>
                    </div>
                    <div class="radio">
                      <mat-radio-button value="Anderes">Anderes Anliegen</mat-radio-button>
                    </div>
                  </mat-radio-group>
                </div>
              </div>
              <div>
                <div class="text-center"><button mat-button matStepperNext class="btn btn-theme btn-lg btn-block" [disabled]="!firstFormGroup.valid">Weiter</button></div>
              </div>
            </form>
          </mat-step>

          <mat-step [stepControl]="secondFormGroup" errorMessage="Bitte ausfüllen.">
            <form [formGroup]="secondFormGroup">
              <ng-template matStepLabel>Projekt</ng-template>
              <h2>Erzählen Sie mir etwas über Ihrem Projekt.</h2>
              <p>Einfach eine kurze Zusammenfassung, damit ich von dem Projekt einen ersten Eindruck erhalten kann.</p>
              <mat-form-field class="form-group">
                <textarea class="form-control" rows="7" matInput formControlName="projekt" name="projekt" placeholder="Ihr Projekt" required></textarea>
                <div *ngIf="projekt.invalid && (projekt.dirty || projekt.touched)" class="alert alert-danger">
                  <div *ngIf="projekt.errors.required">
                    Informationen sind erforderlich.
                  </div>
                  <div *ngIf="projekt.errors.minlength">
                    Informationen müssen mindestens 10 Buchstaben enthalten.
                  </div>
                </div>
              </mat-form-field>
              <div>
                <div class="text-center"><button mat-button matStepperPrevious class="btn btn-theme btn-lg btn-block">Zurück</button></div>
                <div class="text-center"><button mat-button matStepperNext class="btn btn-theme btn-lg btn-block" [disabled]="!secondFormGroup.valid">Weiter</button></div>
              </div>
            </form>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Fertig</ng-template>
            <div>
              <div class="text-center"><button mat-button matStepperPrevious class="btn btn-theme btn-lg btn-block">Zurück</button></div>
              <div class="text-center"><button mat-button (click)="stepper.reset()" class="btn btn-theme btn-lg btn-block">Reset</button></div>
              <div class="text-center"><button class="btn btn-theme btn-lg btn-block" [disabled]="!firstFormGroup.valid" (click)="onSubmit()">Versenden</button></div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
        <!--        <p>Form value: {{ secondFormGroup.value | json }}</p>-->
        <!--        <p>Form status: {{ secondFormGroup.status | json }}</p>-->
        <div *ngIf="data" class="alert  alert-success">{{data}}</div>
        <p id="ds">Bitte beachten Sie die <a routerLink="/datenschutz">Datenschutzerklärung</a></p>
      </div>
      <div class="col-md-6">
        <div class="section-heading">
          <h3>Zeit um uns kennenzulernen,<br>lieber heute als morgen!</h3>
        </div>
        <div class="row">
          <div class="col">
            <p class="animate_fade_in" style="opacity: 1; right: 0px;">
              <i class="fa fa-envelope rounded-icon"></i>
              <a [href]="emailString" target="_top"> info@websoftops.de</a>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="animate_fade_in" style="opacity: 1; right: 0px;">
              <i class="fa fa-phone rounded-icon"></i>
              <a [href]="phoneString" target="_top"> +49 69 257 107 59</a>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="animate_fade_in" style="opacity: 1; right: 0px;">
              <i class="fa fa-whatsapp rounded-icon"></i>
              <a [href]="whatsappString" target="_top"> +49 176 307 134 42</a>
            </p>
          </div>
        </div>
            <img id="logo-contact" alt="WebSoft Ops" src="assets/img/websoftops-reboot-fitted.png">
      </div>
    </div>

<!--    <div class="row mar-top30">-->
<!--      <div class="col">-->
<!--        <h5>Soziale Netzwerke</h5>-->
<!--        <ul class="social-network">-->
<!--          <li><a href="https://www.facebook.com/tahirmzafar85">-->
<!--  						<span class="fa-stack fa-2x">-->
<!--  							<i class="fa fa-circle fa-stack-2x"></i>-->
<!--  							<i class="fa fa-facebook fa-stack-1x fa-inverse"></i>-->
<!--  						</span></a>-->
<!--          </li>-->
<!--          <li><a href="https://www.xing.com/profile/Tahir_Zafar2/">-->
<!--  						<span class="fa-stack fa-2x">-->
<!--  							<i class="fa fa-circle fa-stack-2x"></i>-->
<!--  							<i class="fa fa-xing fa-stack-1x fa-inverse"></i>-->
<!--  						</span></a>-->
<!--          </li>-->
<!--          <li><a href="linkedin.com/in/tahir-zafar-8b643a163">-->
<!--  						<span class="fa-stack fa-2x">-->
<!--  							<i class="fa fa-circle fa-stack-2x"></i>-->
<!--  							<i class="fa fa-linkedin fa-stack-1x fa-inverse"></i>-->
<!--  						</span></a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</section>
