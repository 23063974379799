import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private meta: Meta) {
    meta.addTag({charset: 'UTF-8'});
    meta.addTag({httpEquiv: 'Content-Type', content: 'text/html'});
    meta.addTag({content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'});
    meta.addTag({name: 'viewport', content: 'width=device-width, initial-scale=1'});
    meta.addTag({name: 'description', content: 'Individuale Web- und Softwarelösungen. Softwareentwicklung / Webentwicklung nach Bedarf. Wenn Sie einen Webentwickler / Softwareentwickler suchen, bin ich genau der richtige für den Job!'});
    meta.addTag({name: 'author', content: 'Tahir M. Zafar'});
    meta.addTag({name: 'robots', content: 'INDEX, FOLLOW'});
  }

  addMetaTags() {
      this.meta.addTags([
         {name: 'keywords', content: 'websoftops, websoft ops, webentwickler, webentwicklung, softwareentwickler, softwareenticklung, seo, softwrepflege, datenbankentwickler, programmierung, entwicklung, freiberufler'},
         {name: 'date', content: '2020-04-09', scheme: 'YYYY-MM-DD'},
         {property: 'og:title', content: "WebSoft Ops Tahir M. Zafar | Web- und Softwarelösungen"},
         {property: 'og:type', content: "website"}
      ]);
  }

  getMetaTags() {
        let metaEl: HTMLMetaElement = this.meta.getTag('name= "keywords"');
        console.log(metaEl);
        console.log(metaEl.name);
        console.log(metaEl.content);

        let els: HTMLMetaElement[] = this.meta.getTags('name');
        els.forEach(el => {
          console.log(el);
          console.log(el.name);
          console.log(el.content);
        });
  }

  updateMetaTags() {
        this.meta.updateTag({name: 'description', content: 'Updated: Title and Meta tags examples'});
        this.meta.updateTag({httpEquiv: 'Content-Type', content: 'application/json'}, 'httpEquiv= "Content-Type"');
        this.meta.updateTag({name: 'robots', content: 'NOINDEX, NOFOLLOW'});
        this.meta.updateTag({name: 'keywords', content: 'JavaScript, Angular'});
        this.meta.updateTag({name: 'date', content: '2018-06-03', scheme: 'YYYY-MM-DD'});
        this.meta.updateTag({name: 'author', content: 'VXYZ'});
        this.meta.updateTag({charset: 'UTF-16'}, 'charset= "UTF-8"');
        this.meta.updateTag({property: 'og:title', content: "My Text2"});
        this.meta.updateTag({property: 'og:type', content: "website"});
  }

  removeMetaTags() {
      //Using removeTag
      this.meta.removeTag('name = "description"');
      this.meta.removeTag('name= "keywords"');
      this.meta.removeTag('name = "viewport"');
      this.meta.removeTag('name = "robots"');

      //Using removeTagElement
      let author: HTMLMetaElement = this.meta.getTag('name = "author"');
      this.meta.removeTagElement(author);
      let date: HTMLMetaElement = this.meta.getTag('name = "date"');
      this.meta.removeTagElement(date);
      let contentType: HTMLMetaElement = this.meta.getTag('httpEquiv = "Content-Type"');
      this.meta.removeTagElement(contentType);
      let charset: HTMLMetaElement = this.meta.getTag('charset');
      this.meta.removeTagElement(charset);
      let ogTitle: HTMLMetaElement = this.meta.getTag('property = "og:title"');
      this.meta.removeTagElement(ogTitle);
      let ogType: HTMLMetaElement = this.meta.getTag('property = "og:type"');
      this.meta.removeTagElement(ogType);
  }
}
