<!-- Home area start -->
<!--<div id="home">-->

<!--  <div class="home-text">-->
<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col-md-12">-->
<!--          <div class="brand">-->
<!--            <h1><a href="/">WebSoft Ops</a></h1>-->
<!--            <div class="line-spacer"></div>-->
<!--            <p><span>Freiberuflicher Software/Webentwickler</span></p>-->
<!--            <p><span>in Mühlheim am Main / Remote</span></p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<app-carousel></app-carousel>
<!-- Home area end -->

<!-- About area start -->
<section id="about" class="home-section bg-white">
<!--  <app-teaser></app-teaser>-->
  <app-about></app-about>
</section>
<!-- About area end -->

<!-- spacer start -->
<section id="spacer1" class="home-section spacer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="color-light">
          <h2 class="wow bounceInDown" data-wow-delay="1s">"DevOps adoption means that you are willing to change fast, develop fast, test fast, fail fast, recover fast, learn fast and push the product/features fast to the market."</h2>
          <p class="lead wow bounceInUp" data-wow-delay="2s">Pavan Belagatti</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- spacer end -->
<app-leistungen></app-leistungen>
<app-techstack></app-techstack>
<!-- spacer 2 -->
<section id="spacer2" class="home-section spacer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="color-light">
          <h2 class="wow bounceInDown" data-wow-delay="1s">"It doesn’t really matter where an organization currently is … as long as they realize they are on a journey to continuously improve the way they work.”</h2>
          <p class="lead wow bounceInUp" data-wow-delay="2s">— Kris Buytaert</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- spacer 2 end -->
<app-contact></app-contact>
