<!-- Footer -->
<footer class="page-footer font-small bg-dark">

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    &copy; 2020 <a href="/">WebSoft Ops</a> &nbsp; | &nbsp; <a routerLink="/impressum">Impressum</a> | &nbsp; <a routerLink="/datenschutz">Datenschutz</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
